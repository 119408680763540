import { type FC, type PropsWithChildren, useState, type ReactNode } from 'react';

import { type NextPage } from 'next';

import '../styles/styles.css';

import { type DehydratedState, HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { GlobalState } from '@vakantiesnl/components/src/pages/types';
import { Lato, Montserrat } from '@vakantiesnl/components/src/styles/fonts';
import { ThemeWrapper } from '@vakantiesnl/components/src/styles/themes/themewrapper';
import { ssgPaths } from '@vakantiesnl/components/src/utils/globalState/ssgPaths';
import { FooterProvider } from '@vakantiesnl/services/src/context/footerContext';
import { HeaderProvider } from '@vakantiesnl/services/src/context/headerContext';
import { MicroCopyProvider } from '@vakantiesnl/services/src/context/microCopyContext';
import { ContentfulIdProvider } from '@vakantiesnl/services/src/context/useContentfulIdContext';
import { SeoProvider } from '@vakantiesnl/services/src/context/useSeoContext';
import { ApplicationStoreProvider } from '@vakantiesnl/services/src/stores/applicationStore';
import { GlobalFiltersProvider } from '@vakantiesnl/services/src/stores/globalFiltersStore';
import { getMinutesInMs } from '@vakantiesnl/services/src/util';
import { SSO_TOKEN_COOKIE, checkIfSsoTokenValid, getValueFromSsoToken } from '@vakantiesnl/services/src/util/authToken';
import { getDurationsFromContext, getPaxFromContext } from '@vakantiesnl/services/src/util/globalFilterStateHelpers';
import {
	type VaknlHeaderBlock,
	type FooterBlock,
	type MicroCopy,
	type Seo,
	Theme,
	type Search,
} from '@vakantiesnl/types';
import dayjs from 'dayjs';
import isbot from 'isbot';
import { type AppContext, type AppProps, type NextWebVitalsMetric } from 'next/app';
import cookies from 'next-cookies';
import { TssCacheProvider } from 'tss-react';
import { createEmotionSsrAdvancedApproach } from 'tss-react/next/pagesDir';
import { v4 as uuidv4 } from 'uuid';

import 'dayjs/locale/nl';

dayjs.locale('nl');

if (
	process.env.NEXT_PUBLIC_API_MOCKING === 'true' &&
	typeof window === 'undefined' &&
	// This is a build time variable, forcing webpack tree shaking on prod build
	process.env.INCLUDE_MOCKS === 'true'
) {
	// eslint-disable-next-line no-console
	console.warn('Using MSW mocking is enabled!');
	// eslint-disable-next-line @typescript-eslint/no-require-imports
	require('@vakantiesnl/components/src/e2e/mswMock');
}

type NextDataItems = {
	headerBlockData: VaknlHeaderBlock;
	footerBlockData: FooterBlock;
	microCopyData: MicroCopy;
};

type AppGIP = {
	correlationId: string;
	userAgent?: string;
	partyComposition: Search.PartyComposition[] | null;
	durations: string | number[] | null;
	pageProps: object;
	cookieConsent?: string;
	userId?: string;
};

type NextDataProps = {
	items: NextDataItems;
};

export type GlobalPageProps = {
	dehydratedState?: DehydratedState;
	contentfulId?: string;
	seo: Seo;
	globalState: GlobalState;
};

export type VaknlNextPage<P = Record<string, unknown>> = NextPage<P> & {
	Layout: FC<LayoutRenderProps>;
};

export type LayoutRenderProps = {
	children: ReactNode;
	pageProps: AppGIP & GlobalPageProps;
	cookieConsent?: string;
};

type VaknlAppProps = AppProps<AppGIP & GlobalPageProps & NextDataProps> & {
	Component: VaknlNextPage;
};

interface AppPreviewContext extends AppContext {
	ctx: AppContext['ctx'] & {
		correlationId?: string;
		isBot: boolean;
	};
}

const {
	augmentDocumentWithEmotionCache: augmentDocumentWithEmotionCache_mui,
	withAppEmotionCache: withAppEmotionCache_mui,
} = createEmotionSsrAdvancedApproach({ key: 'mui', prepend: true });

export { augmentDocumentWithEmotionCache_mui };

const {
	augmentDocumentWithEmotionCache: augmentDocumentWithEmotionCache_tss,
	withAppEmotionCache: withAppEmotionCache_tss,
} = createEmotionSsrAdvancedApproach({ key: 'tss' }, TssCacheProvider);

export { augmentDocumentWithEmotionCache_tss };

export function reportWebVitals(nextEvent: NextWebVitalsMetric): void {
	import('@vakantiesnl/services/src/gtm/common').then(({ track }) =>
		import('@vakantiesnl/services/src/gtm/webVitalsEvent').then(({ webVitalsEvent }) =>
			track(webVitalsEvent(nextEvent)),
		),
	);
}

const Noop = ({ children }: PropsWithChildren<Record<string, unknown>>): React.ReactNode => children;

const App = ({
	Component,
	pageProps,
	items,
	userAgent,
	partyComposition,
	cookieConsent,
	durations,
	userId,
}: VaknlAppProps & AppGIP & NextDataProps & GlobalPageProps): JSX.Element => {
	const Layout = Component.Layout || Noop;
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const [state] = useState<NextDataItems>(items || window.__NEXT_DATA__.items);
	const [queryClient] = useState(
		new QueryClient({
			// Default cache is 5 minutes
			defaultOptions: {
				queries: {
					staleTime: getMinutesInMs(5),
					refetchOnWindowFocus: false,
				},
			},
		}),
	);

	// This can potentially be undefined when a page does not return the globalProps
	const globalState = pageProps.globalState;

	return (
		<>
			{/* eslint-disable-next-line react/no-unknown-property */}
			<style jsx global>{`
				:root {
					--Lato: ${Lato.style.fontFamily};
					--Montserrat: ${Montserrat.style.fontFamily};
				}
			`}</style>
			<QueryClientProvider client={queryClient}>
				<HydrationBoundary state={pageProps.dehydratedState}>
					<ApplicationStoreProvider
						staticCanonicalOrigin={globalState?.staticCanonicalOrigin}
						dynamicOrigin={globalState?.dynamicOrigin}
						userId={userId}
						features={globalState?.features}
					>
						<MicroCopyProvider>
							<GlobalFiltersProvider partyComposition={partyComposition} durations={durations}>
								<SeoProvider seo={pageProps.seo}>
									<ContentfulIdProvider contentfulId={pageProps.contentfulId}>
										<HeaderProvider data={state.headerBlockData}>
											<FooterProvider data={state.footerBlockData}>
												<ThemeWrapper theme={Theme.vaknl} userAgent={userAgent}>
													<main>
														<Layout pageProps={pageProps} cookieConsent={cookieConsent}>
															<Component {...pageProps} />
														</Layout>
													</main>
												</ThemeWrapper>
											</FooterProvider>
										</HeaderProvider>
									</ContentfulIdProvider>
								</SeoProvider>
							</GlobalFiltersProvider>
						</MicroCopyProvider>
					</ApplicationStoreProvider>
					<ReactQueryDevtools />
				</HydrationBoundary>
			</QueryClientProvider>
		</>
	);
};

App.getInitialProps = async ({ Component, ctx }: AppPreviewContext): Promise<Record<string, unknown>> => {
	let pageProps = {};

	const userAgent = typeof window === 'undefined' ? ctx.req?.headers['user-agent'] : navigator.userAgent;

	const cookiesToSet = [];
	let { correlationId, sessionId } = cookies(ctx);
	const { cookie_consent } = cookies(ctx);

	const partyComposition = getPaxFromContext(ctx);
	const durations = getDurationsFromContext(ctx);

	if (!correlationId) {
		correlationId = uuidv4();
		cookiesToSet.push(`correlationId=${correlationId}; path=/`);
	}

	/** Set a session ID to identify the user for unleash and also sync this sessionId to the cookies */
	if (!sessionId) {
		sessionId = uuidv4();
		cookiesToSet.push(`sessionId=${sessionId}; path=/`);
	}

	const isSSG = ssgPaths.includes(ctx.pathname);

	ctx.isBot = userAgent ? isbot(userAgent) : false;

	/** Don't fetch feature toggles on pages with SSG, so it will not fetch feature toggles on localhost:3000 (build localhost) */
	if (isSSG) {
		if (!!cookiesToSet.length) {
			ctx.res?.setHeader('Set-Cookie', cookiesToSet);
		}
	} else {
		/** Set the cookies when there's a list of cookies to set */
		if (!!cookiesToSet.length) {
			ctx.res?.setHeader('Set-Cookie', cookiesToSet);
		}

		try {
			/** Pass the origin as a prop for pages with getInitialProps to prevent origin inconsistency between client and serverside rendering */
			if (Component.getInitialProps) {
				pageProps = await Component.getInitialProps(ctx);
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error('Error getting initialprops on the component: ', err);
		}
	}

	let userId = '';

	// Only set userId when GIP is run on the server as ssoToken cookie is httpOnly
	if (ctx.req && ctx.res) {
		const ssoToken = cookies(ctx)[SSO_TOKEN_COOKIE];

		if (ssoToken && checkIfSsoTokenValid(ssoToken)) {
			userId = getValueFromSsoToken(ssoToken, 'user_id');
		}
	}

	return {
		pageProps,
		correlationId,
		userAgent,
		partyComposition,
		durations,
		cookieConsent: cookie_consent,
		userId,
	};
};

export default withAppEmotionCache_mui(withAppEmotionCache_tss(App));
